import {NavigationLinkOnHeaderValue, NavigationModuleTypes} from "utils/interfaces";

export const APP_NAVIGATION: NavigationLinkOnHeaderValue[] = [
    {
        name: "Estratégias",
        path: "app/esoccer/strategies",
        moduleText: "E-Soccer",
        moduleColor: "green",
        type: NavigationModuleTypes.REGULAR
    }
]
